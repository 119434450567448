import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import UserStore from '../../stores/UserStore'
import QuestDescription from '../atoms/questDescription'
import check from '../../utils/check'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../stores/ModalStore'
import AuthStore from '../../stores/AuthStore'
import { useNavigate } from 'react-router-dom'

const onGiveMoney = async (each) => {
  const token = AuthStore?.token
  const questsObj = UserStore?.questLog

  const { type, coin } = each
  const result = await backendApis.money(token, 'POST', { type })
  if (result?.status === 200) {
    UserStore.setQuestLog({ ...questsObj, [type]: false })
    UserStore.setMoneyInfo({
      ...UserStore?.moneyInfo,
      coin: (UserStore?.moneyInfo?.coin || 0) + coin,
    })
    ModalStore.setCurrentBottomSheet({ open: false })
    ModalStore.setCurrentModal({ open: true, type: 'quest', coin })
  }
}

const sprite = [
  {
    type: 'dailyCheckIn',
    icon: '/images/quests/calendar.png',
    title: '매일 출석하기',
    desc: '1,000 받기, 하루 1번',
    buttonText: '출석하기',
    coin: 1000,
    codepush: '1.0.0',
    onClick: ({ each }) => onGiveMoney(each),
  },
  // {
  //   type: 'questDailyInvite',
  //   icon: '/images/quests/invite.png',
  //   title: '친구 초대하기',
  //   desc: '5,000 받기, 하루 1번',
  //   buttonText: '초대하기',
  //   coin: 0,
  //   codepush: '2.2.65',
  //   onClick: () => {
  //     ModalStore.setCurrentBottomSheet({ open: false })
  //     window.location.href = `#shareLink.${JSON.stringify({
  //       shareType: 'ShortsQuestDailyInvite',
  //       title: '[올웨이즈] 재밌는 영상 보고, 돈도 벌자!',
  //       description:
  //         '재밌는 영상을 보면서 현금을 모아보세요. 다 모으면 바로 출금 가능해요.',
  //       imageUrl:
  //         'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
  //     })}`
  //   },
  // },
  {
    type: 'dailyPurchase',
    icon: '/images/quests/purchase.png',
    title: '상품 1개 구매하기',
    desc: '30,000 받기, 하루 1번',
    buttonText: '상품보기',
    coin: 30000,
    codepush: '2.3.28',
    onClick: () => {
      ModalStore.setCurrentBottomSheet({ open: false })
      window.location.href = `#purchase.${JSON.stringify({
        enteringComponent: 'ShortsDailyPurchase',
        title: '비디오 상품 구매',
        source:
          'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/6687dc64-6a39-4ab1-93e2-35c994ee25df.png',
        ratio: 1 / 4,
      })}`
    },
  },
  {
    type: 'rewardedAd',
    icon: '/images/quests/rewardedAd.png',
    title: '광고 보고 골드 받기',
    desc: '5,000 받기, 30분마다',
    buttonText: '광고 보기',
    coin: 5000,
    codepush: '5.0.26',
    onClick: () => {
      if (UserStore.isRewardAdReady) {
        ModalStore.setCurrentBottomSheet({ open: false })
        ModalStore.setCurrentModal({
          open: true,
          type: 'rewardedAd',
        })
      } else {
        ModalStore.setCurrentAlert({
          open: true,
          text: '광고 준비중이에요! 잠시 후 다시 시도해주세요.',
        })
      }
    },
  },
  {
    type: 'dailyTenVideos',
    icon: '/images/quests/tenVideos.png',
    title: '비디오 30초 보기',
    desc: '10,000 받기, 하루 1번',
    buttonText: '시청하기',
    coin: 10000,
    codepush: '1.0.0',
    onClick: ({ each }) => {
      ModalStore.setCurrentBottomSheet({ open: false })
      UserStore?.setOngoingQuest({
        questCount: 30,
        type: 'dailyTenVideos',
        detail: each,
      })
    },
  },
  {
    type: 'meal',
    icon: '/images/quests/rice.png',
    title: '밥 먹고 골드 받기',
    desc: '최대 30,000 받기, 하루 3번',
    buttonText: '돈 받기',
    coin: 10000,
    codepush: '1.0.0',
    onClick: ({ each }) => {
      ModalStore.setCurrentBottomSheet({ open: false })
      ModalStore.setCurrentModal({
        open: true,
        mainType: 'noBackground',
        type: 'meal',
        available: each?.available,
        targetNum: each?.targetNum,
      })
    },
  },
  {
    type: 'night',
    icon: '/images/quests/moon.png',
    title: '자기 전에 골드 받기',
    desc: '20,000 받기, 저녁 7시 이후',
    buttonText: '돈 받기',
    coin: 10000,
    codepush: '1.0.0',
    onClick: ({ navigate }) => {
      ModalStore.setCurrentBottomSheet({ open: false })
      navigate('/night')
    },
  },
]

const checkAvailable = (userCodepush, questsObj) =>
  sprite
    .filter((elem) => {
      const codepushAvailable = check.checkCodepush(
        userCodepush,
        elem?.codepush,
      )
      return codepushAvailable
    })
    .map((each) => {
      const target = each?.type
      const objectTypes = ['meal']
      if (objectTypes.includes(target)) {
        return {
          ...each,
          ...questsObj?.[target],
          buttonAvailable: true,
        }
      }
      if (target === 'rewardedAd') {
        if (questsObj?.[target]?.available === false) {
          return {
            ...each,
            buttonAvailable: false,
            lastClearedAt: questsObj?.[target]?.lastClearedAt,
            timerMinutes: 30,
            onTimeEnd: () => {
              UserStore.setQuestLog({
                ...UserStore.questLog,
                rewardedAd: { available: true },
              })
            },
          }
        }
        if (!UserStore.isRewardAdReady) {
          return {
            ...each,
            buttonAvailable: false,
          }
        }
        return {
          ...each,
          buttonAvailable: true,
        }
      }
      if (questsObj?.[target] !== false) {
        return {
          ...each,
          buttonAvailable: true,
        }
      }
      return {
        ...each,
        buttonAvailable: false,
      }
    })

const CoinQuests = observer(() => {
  const navigate = useNavigate()
  const questsObj = UserStore?.questLog

  if (!questsObj) return null

  const [quests, setQuests] = useState([])
  const userCodepush = UserStore?.userInfo?.codepushVersion

  useEffect(() => {
    const availableQuests = checkAvailable(userCodepush, questsObj)
    setQuests(availableQuests)
  }, [questsObj])

  if (!quests?.length) return null

  return (
    <>
      <div className='h-20' />
      {quests.map((each) => (
        <QuestDescription
          quest={each}
          onClick={() => {
            each.onClick({ each, navigate })
          }}
        />
      ))}
    </>
  )
})

export default CoinQuests
