import ModalStore from '../stores/ModalStore'
import UserStore from '../stores/UserStore'
import VideoStore from '../stores/VideoStore'
import converter from './converter'
import backendApis from './backendApis'
import initialize from './initialize'

const onMessageHandler = async (event, token) => {
  if (event?.data) {
    try {
      const message =
        typeof event?.data === 'string' ? JSON.parse(event?.data) : event?.data
      if (message?.type === 'initWeb' && message?.data) {
        const { userId, codePushVersion, appVersion } = message.data
        const result = await backendApis.userInfo(token, 'GET', {
          projection: JSON.stringify({ userImageUri: 1, userName: 1 }),
        })
        UserStore.setUserInfo({
          userId,
          codepushVersion: codePushVersion,
          appVersion,
          userImageUri: result?.data?.userImageUri,
          userName: result?.data?.userName,
        })
        if (
          converter.safeCodepushVersionCompare(codePushVersion, '2.1.95') < 0
        ) {
          VideoStore.setIsFocused(true)
        }
      }

      if (message?.type === 'backPress') {
        if (
          ModalStore?.currentBottomSheet?.open ||
          ModalStore?.currentModal?.open
        ) {
          ModalStore.setCurrentBottomSheet({ open: false })
          ModalStore.setCurrentModal({ open: false })
        } else {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'goBack',
            }),
          )
        }
      } else if (message?.type === 'appLovinRewardAdReady') {
        UserStore.setIsRewardAdReady(message?.isRewardedAdReady)
      } else if (message?.type === 'notifyAdReward') {
        UserStore.setIsWatchingAd(true)
      }

      if (message?.type === 'contacts') {
        ModalStore.setCurrentModal({
          open: true,
          type: 'contacts',
          mainType: 'noBackground',
        })
      }

      if (message?.isFocused === true) {
        VideoStore.setIsFocused(true)
      } else if (message?.isFocused === false) {
        VideoStore.setIsFocused(false)
      }

      if (message?.reload === true) {
        window.location.reload()
        window.location.href = ''
      }

      if (message?.type === 'dynamicLink') {
        const linkData = message?.data
        if (linkData?.shareType === 'shareVideo') {
          const videoInfo = JSON.parse(decodeURIComponent(linkData?.videoInfo))
          VideoStore.setVideos([videoInfo, ...(VideoStore?.videos || [])])
        }

        // if (linkData?.shareType === 'toRepost') {
        //   UserStore?.setInitialLandingPage({
        //     pathname: '/reposts',
        //     queryObj: linkData,
        //   })
        // }

        if (
          linkData?.shareType === 'dailyInvite' ||
          linkData?.shareType === 'shortsDailyInvite'
        ) {
          const invitorId = linkData?.alwayzInvitorId
          const result = await backendApis.inviteGold(token, 'POST', {
            type: 'dailyInvite',
            invitorId,
          })

          if (result?.status === 200) {
            ModalStore.setCurrentModal({ open: true, type: 'getInvite' })
          } else if (result?.status === 201) {
            ModalStore.setCurrentModal({ open: true, type: 'alreadyInvite' })
          } else if (result?.status === 2011) {
            ModalStore.setCurrentModal({ open: true, type: 'fullInvite' })
          } else if (result?.status === 2012) {
            ModalStore.setCurrentModal({ open: true, type: '3timesInvite' })
          } else if (result?.status === 202) {
            ModalStore.setCurrentModal({ open: true, type: 'selfInvite' })
          } else if (result?.status === 203) {
            ModalStore.setCurrentModal({ open: true, type: 'leftUserInvite' })
          } else {
            ModalStore.setCurrentModal({ open: true, type: 'errorInvite' })
          }
        }

        if (linkData?.shareType === 'ShortsQuestDailyInvite') {
          const invitorId = linkData?.alwayzInvitorId
          const result = await backendApis.inviteGold(token, 'POST', {
            type: 'questDailyInvite',
            invitorId,
          })

          if (result?.status === 200) {
            ModalStore.setCurrentModal({ open: true, type: 'getInvite' })
          } else if (result?.status === 202) {
            ModalStore.setCurrentModal({ open: true, type: 'selfInvite' })
          } else if (result?.status === 203) {
            ModalStore.setCurrentModal({ open: true, type: 'leftUserInvite' })
          } else if (result?.status === 2011) {
            ModalStore.setCurrentModal({ open: true, type: 'fullInvite' })
          } else if (result?.status === 2012) {
            ModalStore.setCurrentModal({ open: true, type: '1timesInvite' })
          } else {
            ModalStore.setCurrentModal({ open: true, type: 'errorInvite' })
          }
        }

        if (
          linkData?.shareType === 'ShortsFriendLink' ||
          linkData?.shareType === 'ShortsContact'
        ) {
          const invitorId = linkData?.alwayzInvitorId
          const result = await backendApis.followee(token, 'POST', {
            invitorId,
            shareType: linkData?.shareType,
          })

          if (result?.status === 200) {
            ModalStore.setCurrentModal({ open: true, type: 'getInvite' })
            initialize.followList(token)
          } else if (result?.status === 201) {
            ModalStore.setCurrentModal({ open: true, type: 'alreadyFollow' })
          } else if (result?.status === 202) {
            ModalStore.setCurrentModal({ open: true, type: 'selfInvite' })
          } else if (result?.status === 203) {
            ModalStore.setCurrentModal({ open: true, type: 'wrongInvite' })
          } else {
            ModalStore.setCurrentModal({ open: true, type: 'errorInvite' })
          }
        }

        if (linkData?.shareType === 'InviteFolloweeLink') {
          const invitorId = linkData?.alwayzInvitorId
          const result = await backendApis.followee(token, 'POST', {
            invitorId,
          })

          if (result?.status === 200) {
            ModalStore.setCurrentModal({ open: true, type: 'InviteFollowee' })
            initialize.followList(token)
          } else if (result?.status === 201) {
            ModalStore.setCurrentModal({ open: true, type: 'alreadyInvite' })
          } else if (result?.status === 202) {
            ModalStore.setCurrentModal({ open: true, type: 'selfInvite' })
          } else {
            ModalStore.setCurrentModal({ open: true, type: 'errorInvite' })
          }
        }

        if (linkData?.shareType === 'InviteTeamLink') {
          const invitorId = linkData?.alwayzInvitorId
          const teamId = linkData?.teamId
          const result = await backendApis.repostTeamJoin(token, 'POST', {
            teamId,
            invitorId,
          })

          if (result?.status === 200 && result?.data) {
            initialize.teamList(token)
            const { teamName, teamId } = result.data
            ModalStore.setCurrentModal({
              open: true,
              type: 'successTeamInvite',
              teamName,
              teamId,
            })
          } else if (result?.status === 201) {
            ModalStore.setCurrentModal({ open: true, type: 'alreadyInvite' })
          } else if (result?.status === 202) {
            ModalStore.setCurrentModal({ open: true, type: 'selfInvite' })
          } else if (result?.status === 203) {
            // team member full
            ModalStore.setCurrentModal({
              open: true,
              type: 'errorTeamInvite',
              msg: '초대 받으신 팀의 정원이 가득 찼어요',
            })
          } else if (result?.status === 204) {
            ModalStore.setCurrentModal({
              open: true,
              type: 'errorTeamInvite',
              msg: '최대 3개의 팀에 참가할 수 있어요',
            })
          } else {
            ModalStore.setCurrentModal({ open: true, type: 'errorInvite' })
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
}

const appBridge = (token) => {
  window.ReactNativeWebView?.postMessage?.(
    JSON.stringify({
      type: 'ready',
    }),
  )

  window.addEventListener('message', (event) => onMessageHandler(event, token))
  document.addEventListener('message', (event) =>
    onMessageHandler(event, token),
  )

  return () => {
    window.removeEventListener('message', (event) =>
      onMessageHandler(event, token),
    )
    document.removeEventListener('message', (event) =>
      onMessageHandler(event, token),
    )
    window.localStorage.removeItem('token')
  }
}

export default appBridge
