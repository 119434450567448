import converter from './converter'

class Check {
  checkCodepush(userCodepush, serviceCodepush) {
    if (converter.safeCodepushVersionCompare(userCodepush, serviceCodepush) >= 0) return true
    return false
  }
}

export default new Check()
